/** @jsx jsx */
import styled from "styled-components"
import { jsx, Styled } from "theme-ui"
import { Grid } from "@theme-ui/components"
import { useStaticQuery, Link, graphql } from "gatsby"
import Layout from "@components/Layouts"
import theme, { rem } from "@src/theme"
import Pill from "@components/Pill"
import CardResults from "@components/CardResults"
import { TAG_OPTIONS } from "@content/forms"
import Spacer from "@components/Spacers"
import SEO from "@components/seo"
import { getSlug } from "@helpers/utils/getSlug"
import { Themed } from "@theme-ui/mdx"
import TwoColumnSection, {
  Block as ColumnBlock,
  ImageBlock as ColumnImageBlock,
} from "@components/TwoColumnSection"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import CalloutStack from "@components/CalloutStack"
import CallToAction from "@components/CallToAction"
import carrotDown from "@components/assets/icons/carrot-down.svg"
import React from "react"
import useGTM from "@src/utils/useGTM"

const Register = props => {
  const data = useStaticQuery(
    graphql`
      query {
        pageData: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/register/index.md" }
        ) {
          frontmatter {
            header {
              ...Header
            }
            registrationForm {
              formEmbed
            }
            ogTitle
            ogdescription
            ogimage
          }
        }
      }
    `
  )

  const { header, registrationForm, ogTitle } = data.pageData.frontmatter
  const { pushEvent } = useGTM();

  console.log("Registration Form", registrationForm)

  // I need to modify the onSubmit function of the form within the MailChimpWrapper to push an event to Google Tag Manager
  const onSubmit = e => {
    pushEvent({
      event: "toolkitDownload",
    })
  }

  React.useEffect(() => {
    // Get the form
    const form = document.querySelector("#mc-embedded-subscribe-form")
    // Add the onSubmit event listener
    form.addEventListener("submit", onSubmit)
    // Remove the event listener when the component unmounts
    return () => {
      form.removeEventListener("submit", onSubmit)
    }
  }, [])

  return (
    <Layout>
      <SEO
        title={ogTitle || header.heading}
        pathname={props.location.pathname}
        image={data?.og?.frontmatter.ogimage}
        description={data?.og?.frontmatter.ogdescription}
      />
      <div sx={{ bg: "pink" }}>
        {header && header.image ? (
          <TwoColumnSection
            col1={
              <div>
                <ColumnBlock
                  styles={{
                    bg: "purple",
                    color: "pink",
                  }}
                >
                  <CTAHeadingBlock heading={header.heading} />
                </ColumnBlock>
                <ColumnBlock
                  styles={{
                    bg: "white",
                    color: "darkPurple",
                  }}
                >
                  <CTAHeadingBlock
                    heading={header.subheading}
                    body={header.subtext}
                    buttonLabel={header.ctaButton?.label}
                    buttonLink={header.ctaButton?.link}
                    smallHeading
                  />
                </ColumnBlock>
              </div>
            }
            col2={<ColumnImageBlock image={header.image} />}
          />
        ) : (
          <TwoColumnSection
            col1={
              <ColumnBlock
                styles={{
                  bg: "purple",
                  color: "pink",
                }}
              >
                <CTAHeadingBlock heading={header.heading} />
              </ColumnBlock>
            }
            col2={
              <ColumnBlock
                styles={{
                  bg: "darkPurple",
                  color: "white",
                }}
              >
                <CTAHeadingBlock
                  heading={header.subheading}
                  body={header.subtext}
                  buttonLabel={header?.ctaButton?.label}
                  buttonLink={header?.ctaButton?.link}
                  smallHeading
                />
              </ColumnBlock>
            }
          />
        )}
      </div>
      <div
        sx={{
          bg: "pink",
          py: [rem(40), rem(80)],
        }}
        id="register-form"
      >
        <MailChimpWrapper
          sx={{
            "#mc_embed_signup": {
              "input.button": {
                variant: "buttons.primary",
                color: "white",
                bg: "darkPurple",
                textDecoration: "none",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  bg: "purple",
                  color: "white",
                },
                height: "unset",
                transition: "all 0s ease 0s",
                lineHeight: "unset",
                borderRadius: "unset",
              },
            },
          }}
          dangerouslySetInnerHTML={{ __html: registrationForm.formEmbed }}
        ></MailChimpWrapper>
      </div>
    </Layout>
  )
}

const MailChimpWrapper = styled.div`
  margin: 0 auto;
  max-width: 600px;
  padding: 1rem 1rem;
  background-color: ${theme.colors.white};
  #mc_embed_signup {
    width: 100%;
    h2 {
      display: inline-block;
      font-family: ${theme.fonts.heading};
      font-size: ${rem(30)};
      color: ${theme.colors.darkPurple};
      white-space: pre-wrap;
      /* background-color: #05285C; */
      /* border-left: 0.1em solid #05285C;
            border-right: 0.2em solid #05285C; */
      /* transform: rotate(-0.5deg); */
      padding: ${rem(2)};
    }

    .indicates-required {
      font-family: ${theme.fonts.body};
    }

    div.mc-field-group {
    }
    div.mc-field-group label {
      display: block;
      font-size: ${rem(18)};
      font-weight: 900;
      margin-bottom: ${rem(14)};
      font-family: ${theme.fonts.heading};
    }

    div.mc-field-group input {
      border: 3px solid ${theme.colors.grey};
      padding: ${rem(8)};
      font-size: ${rem(18)};
      width: 100%;
      font-family: ${theme.fonts.body};
      border-radius: 0;
      color: ${theme.colors.darkPurple};
      height: ${rem(50)};
    }

    div.mc-field-group select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      background: url(${carrotDown}) no-repeat right #ddd;
      background-position-x: 97%;
      background-color: transparent;
      border: 3px solid;
      border-color: ${theme.colors.grey};
      padding: ${rem(8)};
      font-size: ${rem(18)};
      height: ${rem(50)};
      width: 100%;
      font-family: ${theme.fonts.body};
      border-radius: 0;
      color: ${theme.colors.darkPurple};
    }
  }
`

export default Register
